


































import CreateMembershipModel from '@/src/services/viewModel/resource/CreateMembershipRequestViewModel';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
  }),
  computed: {
    innerValue: {
      get(): CreateMembershipModel {
        return this.value ?? {};
      },
      set(value: CreateMembershipModel) {
        this.$emit('input', value);
      },
    },
  },
});
